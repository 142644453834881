import React from 'react'
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import LinkBox from '../../components/linkbox'
import LinkBox2 from '../../components/linkbox2'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Heading from '../../components/heading'
import Img from 'gatsby-image'

import HotelCardHatta from '../../components/hotel/card/hatta'
import HotelCardHeian from '../../components/hotel/card/heian'
import HotelCardKanpoIsawa from '../../components/hotel/card/kanpoisawa'
import HotelCardKikori from '../../components/hotel/card/kikori'
import HotelCardMine from '../../components/hotel/card/mine'
import HotelCardUkai from '../../components/hotel/card/ukai'

import BackgroundImg from 'gatsby-background-image'
import { Link } from 'gatsby'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import teal from '@material-ui/core/colors/teal'
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  hero: {
        // minHeight: '60vh',
        [theme.breakpoints.up('sm')]: {
            minHeight: '60vh',
        },
        padding: '15px',
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    wrapper: {
        // minHeight: '60vh',
         [theme.breakpoints.up('sm')]: {
            minHeight: '60vh',
        },
        padding: '25px',
        backgroundColor: 'rgba(0,0,0,.3)',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    heading2: {

    },
    paragraphs: {
        marginBottom: '1rem',
    },
    paragraph: {
        marginBottom: '.5rem',
    },
    span: {

    },
    clip: {
        backgroundColor: "#48bd96",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: "#ffe066",
            color: "#000000",
        },
    },
    avatar: {
        backgroundColor: "#30a679",
        color: "#ffffff",
    },
    imageContainer: {

    },
    landingHeroContainer: {
      backgroundColor: "#30a679",
      // backgroundColor: "#c8ebe0",
      [theme.breakpoints.down('xs')]: {
        // backgroundColor: "#48bd96",
      }
    },
    landingHero: {
        minHeight: '35vh',
        padding: '15px',
        // backgroundColor: 'rgba(0,0,0,.3)',
        // [theme.breakpoints.down('xs')]: {
        //   backgroundColor: "#48bd96",
        // }
    },
    landingHeroWrapper: {
        minHeight: '35vh',
        padding: '25px',
        color: '#ffffff',
        borderColor: 'rgba(255,255,255,.5)',
        // borderColor: 'rgba(200,235,224,.5)',
        // borderColor: 'rgba(48,166,121,1)',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
    },
    landingHeroHeading2: {
      color: '#ffe066',
    },
    landingHeroHeading3: {

    },
    gridContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    contentContainer: {
      padding: '0 30px 30px',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    backgroundImageContainer: {
      // backgroundSize: 'auto',
      // [theme.breakpoints.up('md')]: {
      //     minHeight: '60vh',
      // },
    },
    internal: {
        marginTop: '30px !important',
        marginRight: theme.spacing(1),
        backgroundColor: '#48bd96',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#30a679',
        },
    },
    contentContainer2: {
      padding: '30px 30px 0',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    landingHeroHeading2p: {
      marginBottom: 0,
    },
    landingHeroHeading2sub: {

    },
}));

const IndexPage = ({ data }) => {
  const classes = useStyles();
  const language = 'zh_hans';
  const stations = [
    'takao',
    'otsuki',
    'katsumura',
    'enzan',
    'yamanashishi',
    'kofu',
    'kobuchisawa'
  ];
  let tmp = '';
  const nodes = data.allStationsJson.edges.map(edge => edge.node)
  const takao = nodes.filter(function(node, index) {
    if (node.name === 'takao') {
      return node;
    }
  });
  const nodes2 =data.allRailroadJson.edges.map(edge => edge.node);
  const notChild = false;
  const child = true;
  return (
    <Layout language={language} slug="">
      <SEO title="日本城乡之旅" />
      <div className={classes.landingHeroContainer}>
        <section className={classes.landingHero}><div className={classes.landingHeroWrapper}>
          <p className={classes.landingHeroHeading2p}><span className={classes.landingHeroHeading2sub}>Japan Rural Roaming</span></p>
          <h2><span className={classes.landingHeroHeading2}>日本城乡之旅</span></h2>
          <h3><span className={classes.landingHeroHeading3}>JR铁道自在行 城乡小镇好旅情</span></h3>
          <div className={classes.paragraphs}>
            <p className={classes.paragraph}><span className={classes.span}>到一些没没无闻的小镇走走吧。</span></p>
            <p className={classes.paragraph}><span className={classes.span}>鲜为人知的绝色美景、独一无二的在地美食、萍水相逢的人情温暖……</span></p>
            <p className={classes.paragraph}><span className={classes.span}>小城故事多，不是吗？</span></p>
          </div>
        </div></section>
      </div>
      <section>
        <Heading title="富士黄金三角" isChild={notChild} />
        <div className={classes.imageContainer}><Link to="/zh_hans/campaign/mt-fuji-golden-triangle"><Img objectFit="contain" fluid={data.mfgt.childImageSharp.fluid} /></Link></div>
        <div className={classes.contentContainer2}>
          <p>富士山、大月站与石和温泉站，</p>
          {/* <p>たのしい、おいしい、わくわくがいっぱい。</p> */}
          <p>我们叫它「富士黄金三角」。</p>
          <Button
              href="/zh_hans/campaign/mt-fuji-golden-triangle"
              variant="contained"
              endIcon={<KeyboardArrowRight />}
              className={classes.internal}
          >富士黄金三角</Button>
        </div>
      </section>
      <section>
        <Heading title="JR中央本線" isChild={notChild} />
        <div className={classes.contentContainer}>
          <p>从东京的新宿站到长野的松元站，总长225公里。</p>
          <p>这次我们要从中为您介绍八个魅力十足的城乡车站和附近的景点，最远到山梨县境的小渊泽。</p>
          <p></p>
          <p>在东京的你有了一天的空闲吗？从新宿过去只要两个小时哦！</p>
          <p>带着你的JR Pass，跳上电车立刻出发！</p>
        </div>
        <LinkBox nodes={nodes} stationName="takao" image={data.takao.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="otsuki" image={data.otsuki.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="katsunuma" image={data.katsunuma.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="enzan" image={data.enzan.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="yamanashishi" image={data.yamanashishi.childImageSharp.fluid} language={language} />
        <LinkBox2 nodes={nodes2} stationName="isawaonsen" image={data.isawaonsen.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="kofu" image={data.kofu.childImageSharp.fluid} language={language} />
        <LinkBox nodes={nodes} stationName="kobuchisawa" image={data.kobuchisawa.childImageSharp.fluid} language={language} />
      </section>
      <section>
        <Heading title="Hotels" isChild={notChild} />
        <div className={classes.imageContainer}><Link to="/zh_hans/station/isawaonsen/takeda-24-generals"><Img objectFit="contain" fluid={data.takeda24.childImageSharp.fluid} /></Link></div>
        <div className={classes.gridContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <HotelCardHatta language={language} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HotelCardHeian language={language} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HotelCardKanpoIsawa language={language} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HotelCardKikori language={language} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HotelCardMine language={language} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <HotelCardUkai language={language} />
            </Grid>
          </Grid>
        </div>
      </section>
      {/* {stations.map(station => {
        tmp = nodes.filter(function(node, index) {
          if (node.name === station) {
            return node;
          }
        });
        return (<div>{tmp.name}</div>);
      })} */}
      {/* <BackgroundImg
                Tag="section"
                fluid={data.takao.childImageSharp.fluid}
                backgourndColor={`#48bd98`}
                style={{
                    minHeight: '80vh',
                    backgroundSize: 'auto',
                }}
            >
                <section className={classes.hero}><div>
                    <h1><span className={classes.heading1}>{data.allStationsJson.pages.ja.stationName}</span></h1>
                    <div className={classes.paragraphs}>
                        {
                            data.stationsJson.pages.ja.descriptions.map((description, i) => (
                                <p key={i}><span className={classes.paragraph}>{description}</span></p>
                            ))
                        }
                    </div>
                </div></section>
            </BackgroundImg> */}
      {/* <pre>{JSON.stringify(nodes2, null, 4)}</pre> */}
    </Layout>
  )
}

export const query = graphql `
  {
    allStationsJson {
      edges {
        node {
          id
          name
          pages {
            zh_hans {
              descriptions
              stationName
            }
          }
        }
      }
    }
    allRailroadJson {
      edges {
        node {
          id
          name
          stationName {
            en
            ja
            zh_hans
            zh_hant
          }
          pages {
            ja {
              descriptions
            }
            en {
              descriptions
            }
            zh_hans {
              descriptions
            }
            zh_hant {
              descriptions
            }
          }
        }
      }
    }
    mfgt:file(relativePath: {eq: "campaign/mfgt/enja.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000, pngQuality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    isawaonsen:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    takao:file(relativePath: {eq: "stations/takao/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    otsuki:file(relativePath: {eq: "stations/otsuki/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    katsunuma:file(relativePath: {eq: "stations/katsunuma/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    enzan:file(relativePath: {eq: "stations/enzan/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    yamanashishi:file(relativePath: {eq: "stations/yamanashishi/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    kofu:file(relativePath: {eq: "stations/kofu/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    kobuchisawa:file(relativePath: {eq: "stations/kobuchisawa/hero.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 1000, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
            }

        }
        relativeDirectory
    }
    takeda24:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    
  }
`

export default IndexPage
